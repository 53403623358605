/***************************
Fonts
***************************/
@font-face {
    font-family: 'AwesomeSerif';
    src: url('/assets/fonts/awesome-sarif/awesomeserif-mediumregular-webfont.woff2') format('woff2'),
         url('/assets/fonts/awesome-sarif/awesomeserif-mediumregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AwesomeSerif-varible-italic';
    src: url('/assets/fonts/awesome-sarif/variable/awesome_serif_italic_var-vf-webfont.woff2') format('woff2'),
         url('/assets/fonts/awesome-sarif/variable/awesome_serif_italic_var-vf-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Magnetik';
    src: url('/assets/fonts/magnetik/magnetik-semibold-webfont.woff2') format('woff2'),
         url('/assets/fonts/magnetik/magnetik-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/*************************** 
Variables
***************************/
//colors
$blue: #43548F;
$orange: #EF6335;
$white: #fff;
$light-grey: #FFBEBF;
$page-bg: #FFFBF4;

$primary-font-regular: 'Magnetik', serif;
$secondary-font-italic: 'AwesomeSerif-varible-italic', serif;
/*************************** 
HTML elements
***************************/
html {
    font-size: 22px;
    scroll-behavior: smooth;
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    position: relative;
    min-height: 100.1%;
    font-size: 1rem;
    margin: 0;
    padding-bottom: 38px;
    font-family: 'Magnetik', serif;
    background-color: $page-bg;
}

a {
    text-decoration: none;
    font-weight: 600;
}

/****************************
Helpers
****************************/
.container {
    margin-inline: auto;
    width: 100%;
    max-width: 1200px;
    @media(max-width: 992px) { overflow: hidden; }
    @media(min-width: 1000px) { max-width: 992px; }
    @media(min-width: 1400px) { max-width: 1280px; }
    @media(min-width: 1800px) { max-width: 1520px; }
}

.mobile-only {
    @media(min-width: 992px) { display: none !important; }
}
.desktop-only {
    @media(max-width: 991px) { display: none !important; }
}

/****************************
Scroller
****************************/
#scroller {
    height: 71px;
    background-color: $orange;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 38px;
    z-index: 1;
    margin-top: -71px;
    @media(max-width: 991px) {
        margin-top: 0;
        bottom: 0;
    }
    p {
        margin-right: 8rem;
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        color: $light-grey;
        @media(max-width: 767px) {
            margin-right: 1rem;
        }
        span {
            font-family: $secondary-font-italic;
            font-weight: 600;
            font-size: 1.31rem;
        }
    }
}

/*****************************
body components
*****************************/
.top-bar {
    background-color: $blue;
    overflow: hidden;
    .container {
        height: 77px;
        display: flex;
        align-items: center;
        @media(max-width: 991px) {
            padding-inline: 20px;
            max-width: 100%;
        }
    }
    img {
        width: 140px;
        @media(max-width: 767px) { width: 104px; }
    }
}

.main {
    position: relative;
    padding-top: 2.7rem;
    @media(max-width: 767px) { 
        padding-top: 2rem; 
        overflow: hidden;
    }

    .default-bg, .large-bg, .small-bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: auto 100%;
        @media(max-width: 767px) {
            background-size: 100% auto;
        }
    }

    .default-bg {
        @media(min-width: 1500px) { display: none; }
        @media(max-width: 767px) { display: none; }
    }

    .large-bg {
        @media(max-width: 1499px) { display: none; }
    }

    .small-bg {
        background-position-y: 13%;
        @media(min-width: 768px) { display: none; }
    }

    .text-wrapper {
        overflow: auto;
        @media(max-width: 991px) { 
            display: flex; 
            flex-wrap: wrap;
        }
        @media(max-width: 767px) {
            align-items: flex-end;
            margin-bottom: 1.5rem;
        }
        &::after {
            content: "";
            clear: both;
            display: table;
        }

        img {
            width: 90%;
        }

        .trapezoid-shape {
            width: 70%;
            height: auto;
            float: left;
            shape-outside: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
            @media(max-width: 991px) { 
                float: none; 
                width: 55%;
            }
            @media(max-width: 767px) {
                position: relative;
                right: 10px;
                shape-outside: initial;
                clip-path: initial;
                img {
                    width: 100%;
                }
            }
        }

        .text {
            @media(max-width: 991px) { 
                width: 45%; 
            }
        }
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        justify-content: space-between;

        p {
            font-weight: 600;
            color: $blue;
            margin-top: 0;
            margin-bottom: .5rem;

            br {
                @media(max-width: 767px) { display: none; }
            }

            em {
                letter-spacing: -1px;
                font-style: normal;
            }
        }

        .videos {
            width: 55%;
            display: flex;
            gap: 30px;
            height: 700px;
            @media(min-width: 1800px) { height: 850px; }
            @media(max-width: 991px) {
                width: 100%;
                height: 600px;
                padding-top: 20px;
                padding-bottom: 32px;
            }
            @media(max-width: 767px) {
                height: 312px;
            }
            img {
                width: 100%;
            }

            .big-video {
                width: 56%;
                display: flex;
                @media(max-width: 991px) {
                    width: 50%;
                }
                .video {
                    overflow: hidden;
                    display: flex;
                    position: relative;
                    border-radius: 0 100px;
                    -webkit-border-radius: 0 100px 0 100px;
                    width: 100%;

                    iframe {
                        width: 140%;
                        height: 140%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .small-videos {
                width: 38%;
                overflow: hidden;
            }
        }

        .circle-video {
            margin-bottom: 30px;
            @media(max-width: 767px) { margin-bottom: 0; }
                    
            .video {
                position: relative;
                height: auto;
                aspect-ratio: 1/1;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                overflow: hidden;
                @media(max-width: 991px) {
                    width: 100%;
                }

                iframe {
                    width: 200%;
                }
            }
        }

        .dome-video {
            display: flex;
            height: 61%;
            @media(max-width: 991px) {
                width: 50%;
                height: auto;
            }
            .video {
                border-radius: 143.5px 143.5px 0px 0px;
                -webkit-border-radius: 143.5px 143.5px 0 0;
                overflow: hidden;
                width: 100%;
                position: relative;

                iframe {
                    width: 240%;
                }
            }
        }

        iframe {
            width: 140%;
            height: auto;
            aspect-ratio: 1/1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
        }

        .video-cover {
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            position: absolute;
            width: 150%;
            height: 150%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            z-index: 11;
            &.show {
                opacity: 1;
            }
        }

        .content {
            width: 45%;
            position: relative;
            @media(min-width: 1400px) {
                left: 42px;
                top: 30px;
            }
            @media(min-width: 1800px) {
                top: 45px;
            }
            @media(max-width: 991px) { 
                width: 100%; 

                p {
                    padding-inline: 25px;
                }
            }

            p {
                @media(min-width: 1800px) { font-size: 26px; }
                @media(max-width: 767px) {
                    padding-left: 20px;
                    font-size: 20px;
                    padding-right: 25px;
                    left: 0 !important;
                    margin-bottom: 1.5rem;
                }
            }

            >p {
                position: relative;
                left: 13px;
                @media(min-width: 1800px) {
                    margin-bottom: 2rem;
                }
            }

            p.mobile-only + p {
                @media(max-width: 767px) {
                    margin-bottom: 2rem;
                }
            }
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            align-items: center;
            @media(min-width: 992px) {
                padding-left: 10px;
            }
            @media(max-width: 991px) {
                justify-content: center;
            }
            @media(max-width: 767px) {
                gap: 15px;
            }

            i {
                display: inline-block;
                background-size: 100%;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
                @media(min-width: 1800px) {
                    width: 55px;
                    height: 55px;
                }
                @media(max-width: 767px) {
                    width: 75px;
                    height: 75px;
                }
            }

            .plane {
                i {
                    width: 70px;
                    height: 66px;
                    @media(min-width: 1800px) {
                        width: 80px;
                        height: 77px;
                    }
                    @media(max-width: 767px) {
                        width: 80px;
                        height: 76px;
                    }

                }
            }

            a {
                font-size: 1.09rem;
                color: $blue;
                @media(max-width: 767px) { font-size: 1.5rem; }

                i {
                    font-style: normal;
                    display: inline-block;
                }

                span {
                    font-family: $secondary-font-italic;
                    font-size: 1.4rem;
                }
            }
        }
    }

    .mail-link {
        position: relative;
        bottom: 10px;

        .pointer {
            width: 29px;
            position: absolute;
            z-index: 1;
            left: 48%;
            top: -35%;
            animation: pulse 1s infinite;
            @media(max-width: 767px) {
                left: 37%;
            }
        }

        i {
            display: block !important;
            font-size: .84rem;
            width: initial !important;
            height: initial !important;
            @media(max-width: 767px) { font-size: 1rem; }
        }

        em {
            letter-spacing: -1px;
            font-style: normal;
        }
    }
}

.glass-cursor {
    position: fixed;
    width: 150px;
    height: 150px;
    //background: $white;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 50%;
    pointer-events: none;
    z-index: 9999;
    transition: transform 0.1s ease-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    top: 0;
    //mix-blend-mode: multiply;
    //opacity: .8;
    @media(max-width: 991px) { display: none; }
}
  
.magnify {
    transition: transform 0.3s ease;
    @media(max-width: 991px) { display: none; }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}